import { createGlobalStyle } from "styled-components"
// import theme from "../../style/theme"
const GlobalStyles = createGlobalStyle`



* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: ${props => props.theme.fonts};

}

.flockler-loading {
  display: hidden !important;
  height: 0px !important;
  /* border: 1px solid red; */
}
  html{
    overflow-x: hidden;
    
  }
  body {
    background-color: ${props => props.theme.tints.white};
    font-family: ${props => props.theme.fonts};
    max-width: 100vw;
    display: flex;
    justify-content: center;


  }
  h1 {
   
    color: ${props => props.theme.colors.primary};
    line-height: 5.3rem;
    font-size: 80px;
    text-transform: uppercase;
    font-weight: 600;
    @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 40px;
      line-height: 45px;
  }
  }
  h2{
    font-size: 3.125rem;
    line-height: 3.44rem;
    font-weight: 600;
    text-transform: uppercase;
    @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 30px;
      line-height: 35px;
  }
    
  }
  h3 {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 2.8125rem;
    @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 24px;
      line-height: 29px;
  }
}
h4 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.3125rem;
  @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 20px;
    line-height: 23px;
  }
}  
h5 {
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.9375rem;
  /* margin-bottom: 1.05rem; */
  text-transform: uppercase;
  @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 18px;
    line-height: 23px;
  }
}
h6 {
  font-size: 1.25rem;
  line-height:  1.563rem;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
  /* margin-bottom: 16px; */
  letter-spacing: 2px;
  @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 0.875rem;
    line-height: 1.118rem;
  }
  }
  a {
    color: ${props => props.theme.colors.turquoise};
    text-decoration: none;
    &:hover {
      text-decoration: underline;

    }
  }
  b {
    font-size:1.4rem;
  }
  p{
    font-size: 1rem;
    line-height: 1.56rem;
    font-weight: 400;
    color:${props => props.theme.colors.grey1};
    > span {
      font-size: 1rem !important;;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 14px;
      > span {
      font-size: 14px !important;
    }
  }

  }
  .spacing {
    letter-spacing: 2px;
  }


  
input[type='radio'] {
  opacity: 0;
  position: absolute;
}
input[type='radio'] + label {
  margin-right: 8px;
  position: relative;
  margin: 0 8px 0 0;
  margin-bottom: 10px !important;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  /* padding-left: 20px; */
  cursor: pointer;
  
  display: inline-block;
  color:   ${props => props.theme.colors.grey1};
  /* border-radius: 5px; */
  background-color: white;
  //min-width: 101px;  //taken out to for shedule event row
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 18px;
  padding-right: 18px;
  border: 1px solid #DADADA;
  text-align: center;
  /* min-height: 60px; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    min-height: 50px;
    padding-top: 15px;
  padding-bottom: 15px;
}
  &:hover{
    background-color: #006688;
    border: 1px solid #006688;
    color: white;
  }

}

input[type='radio']:checked + label {
  color: white;
  
  background-color: ${props => props.theme.colors.turquoise};
  border: 1px solid ${props => props.theme.colors.turquoise};
}

label {
  color: #4a4a4a;
  margin-bottom: 14px;
  display:block;
  @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 16px;
}
}

input[type='submit'] {
  transition: all 0.2s;
  margin-bottom: 43px;
  font-size: 18px;
  height: 60px;
  min-width: 360px;
  flex-shrink: 0;
  margin-top: 33px;
  padding-right: 11px;
  padding-left: 11px;
  color: white;
  border-radius: 30px;
  background-color: ${props => props.theme.colors.turquoise};;
  font-weight: 700;
  border: none;
  outline: none;
  &:active {
    border: none;
    outline: none;
  }
  &:focus {
    border: 1px solid ${props => props.theme.colors.turquoise};
    outline: none;
  }
  &:hover {
    background-color: #006688;
    border: 1px solid #006688;
  }
  &:disabled {
    background-color: #c2a3af;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    min-width: 250px;
  }
}
hr{
  margin-top: 30px;
  margin-bottom: 30px;
}

label {

  color:  ${props => props.theme.colors.grey1};
  font-family: "Barlow Condensed";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom:14px;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'] {
  &:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid ${props => props.theme.colors.turquoise};
  }
  + p {
    margin-bottom: 20px;
  }
  height: 60px;
  width: 100%;
  flex-shrink: 0;
  /* margin-bottom: 20px; */
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid #dadada;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  /* border-radius: 5px; */
  background-color: #fff;
  font-size: 16px;
  line-height: 1.9rem;
  font-weight: 500;
  transition: background-color 200ms ease, border-color 200ms ease,
    box-shadow 200ms ease;
 
    @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    height: 50px;
  }
  &.validation-error{
    border: 1px solid #D0021B !important;
  }
  &.validation-success{
    border: 1px solid #008D36;
}
}

select{
  appearance:none;
  padding: 0 auto;
  position: relative;
  /* width: 100px; */
  padding: 0px 24px;
  z-index: 3;
  font-size: 16px;
  cursor: pointer;
  color: #4A4A4A !important;
  display: flex;
  background: white;
  height: 100%;
  -webkit-box-shadow: inset 0px 1px 1px 2px rgba(0,0,0,0.05); 
box-shadow: inset 0px 1px 1px 2px rgba(0,0,0,0.05);
  @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
  
  select.decorated option:hover {
    box-shadow: 0 0 10px 100px #1882A8 inset;
}
}
select.decorated option:hover {
    box-shadow: 0 0 10px 100px #1882A8 inset;
}
option{
  margin:auto 0;
  padding: 0 1rem;
  color:  ${props => props.theme.colors.grey1};
  padding: 0px 24px;
  height: 100%; 
  font-size: 18px;
  line-height: 22px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-shadow: inset 0 1.5px 2px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid #ddd;
  select.decorated option:hover {
    box-shadow: 0 0 10px 100px #1882A8 inset;
}
}
select.decorated option:hover {
    box-shadow: 0 0 10px 100px #1882A8 inset;
}
`

export default GlobalStyles
