import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, StaticQuery } from "gatsby"

const PartnersInfiniteCarousel = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPartner {
            edges {
              node {
                id
                title
                slug
                PartnerInfo {
                  tags
                  partnershipIntro
                  partnershipLogo {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 400, layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const partnersList = data.allWpPartner.edges.map(v => v.node)
        return (
          <Container>
            <SwiperContainer>
              <Swiper
                spaceBetween={50}
                speed={2000}
                slidesPerView="auto"
                loop={true}
                freeMode={false}
                autoplay={{
                  disableOnInteraction: false,
                  waitForTransition: true,
                }}
              >
                <SwipperWrapper>
                  {partnersList.map((item, id) => {
                    let image
                    try {
                      image = item.PartnerInfo.partnershipLogo.localFile.childImageSharp.gatsbyImageData
                    } catch {
                      return null
                    }
                    return (
                      <SwiperSlide key={id} className="swiper-slide" data-swiper-autoplay="100">
                        <FooterPartnersGrid
                        // to={"/partners/" + item.slug}
                        >
                          <GatsbyImage
                            image={image}
                            style={{ height: "86", width: "80" }}
                            imgStyle={{ objectFit: "cover" }}
                            key={id}
                            alt={"Partners"}
                          />
                        </FooterPartnersGrid>
                      </SwiperSlide>
                    )
                  })}
                </SwipperWrapper>
              </Swiper>
            </SwiperContainer>
          </Container>
        )
      }}
    />
  )
}

export default PartnersInfiniteCarousel

const Container = styled.section`
  overflow: hidden;
  max-width: 100%;
  margin: auto;

  .swiper-container {
    overflow: visible;
    /* transition: transform 0.8s ease; */
    max-width: 100%;
    margin-right: auto;
    /* transition: transform 0.4s ease; */
    transition-timing-function: linear;
    @media (min-width: 1024px) {
      max-width: 100%;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 150px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border: 1px solid #eeeeee;
    height: 100px;
  }
`
const SwiperContainer = styled.div`
  cursor: grab;
  width: 100vw;
  height: 160px;
  /* max-width: 1140px; */
  margin: auto;
  position: relative;
  background-color: #fafafa;
  border: 1px solid #f4f4f4;
  display: flex;
  align-items: center;
`
const SwipperWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition-timing-function: linear;
`

const FooterPartnersGrid = styled.div`
  ///changed from link to div until go live @notlive
  width: 150px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
