import { Container, IconButton, makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Cookie from "universal-cookie"
import CloseIcon from "@material-ui/icons/Close"
const cookies = new Cookie()

interface ICookieBar {
  closeCookies: any
}
const useStyles = makeStyles(theme => ({
  customHoverFocus: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#575757" },
    backgroundColor: "#2e2e2e",
    color: "white",
  },
  icon: {
    fill: "white",
  },
}))
export const CookieBar: React.FC<ICookieBar> = ({ closeCookies }) => {
  const text = "This website uses cookies."
  const [displayCookie, toggleCookie] = useState(false)
  const classes = useStyles()
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (typeof window !== "undefined" && typeof document !== "undefined") {
        const element: HTMLElement | null = document.getElementById("cookie-container")

        if (!cookies.get("cookiesAccepted")) {
          if (element) {
            element.style.animation = "fadeInUp 2s ease forwards"
            toggleCookie(true)
          }
        }
      }
    }
    return () => {
      isMounted = false
    }
  }, [])
  const cookiesClosed = () => {
    closeCookies(true)
  }
  const handleCross = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const element: HTMLElement | null = document.getElementById("cookie-container")
      const expiryDate = new Date()
      expiryDate.setYear(expiryDate.getFullYear() + 1)

      cookies.set("cookiesAccepted", "true", {
        path: "/",
        expires: expiryDate,
      })
      if (element) {
        element.style.animation = "fadeOutDown 5s ease"
        toggleCookie(false)
      }
    }
  }

  return (
    <MainContainer cookieVisible={displayCookie} id="cookie-container">
      <Container maxWidth={"xl"}>
        <Inner>
          <Text>
            {text} <a href={"/privacy-policy"}>Read more</a>
          </Text>
          <IconButton
            className={classes.customHoverFocus}
            onClick={() => {
              handleCross()
              cookiesClosed()
            }}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        </Inner>
      </Container>
    </MainContainer>
  )
}

interface IContainer {
  cookieVisible: boolean
}
const MainContainer = styled.div<IContainer>`
  background: ${props => props.theme.colors.primary};
  width: 100%;
  height: 90px;
  position: fixed;
  left: 0;
  bottom: -100%;
  bottom: ${props => (props.cookieVisible ? "0px" : "-100%")};
  z-index: 500;
  transition: bottom 3s ease-in;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    height: 5rem;
  }
`
const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Text = styled.p`
  /* border: 1px solid red; */
  color: #fff;
  font-size: 1.2rem;
  line-height: 2.4rem;
  font-weight: 300;
  margin: 0;
  width: 70vw;
  text-align: left;
  @media only screen and (min-width: 1024px) {
    font-size: 1.5rem;
    line-height: 1.5rem;
    width: 784px;
  }

  & a,
  & a:visited,
  & a:hover,
  & a:active {
    cursor: pointer;
    color: white;
    text-decoration: underline;
    font-size: inherit;
  }

  & a:hover {
    opacity: 0.9;
  }
`
