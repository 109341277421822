import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql, Link, StaticQuery } from "gatsby"
import Search from "../../assets/svg/Search.svg"

import { Container } from "@material-ui/core"
import { ButtonSecondary, SizeType } from "../Buttons/Buttons"
import { getUser, isLoggedIn } from "../../services/auth"
import Profile from "../../assets/svg/profile.svg"
const TopBar: React.FC = () => {
  const [user, setUser] = useState(undefined)
  const [loggedIn, setIsLoggedIn] = useState(undefined)

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setUser(getUser())
      setIsLoggedIn(isLoggedIn())
    }

    return () => {
      mounted = false
    }
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              amplifyBranch
            }
          }
        }
      `}
      render={data => {
        return (
          <FullWidth>
            <TopBarWrapper>
              <TopBarGFLinks>
                <TopBarLinkEx href="https://www.graduatefashionfoundation.com/" target="_blank">
                  GRADUATE FASHION FOUNDATION
                </TopBarLinkEx>
                <TopBarLink $whiteStyle="true" to={"/"}>
                  GRADUATE FASHION WEEK
                </TopBarLink>
              </TopBarGFLinks>
              {/* Activate during phase 2 */}

            
                <TopBarLogin>
                  <Link to={"/search"}>
                    <SearchIcon />
                  </Link>
            
                  {loggedIn ? (
                    <ProfileLink to={"/events/account"}>
                      <ProfileIcon />
                      {user.emailAddress}
                    </ProfileLink>
                  ) : null}

                  {!loggedIn || loggedIn === undefined ? (
                    <ButtonSecondary size={SizeType.small} to={"/events/login"}>
                      SIGN IN
                    </ButtonSecondary>
                  ) : null}
                 
                </TopBarLogin>
         
            </TopBarWrapper>
          </FullWidth>
        )
      }}
    />
  )
}

export default TopBar

const FullWidth = styled.div`
  z-index: 1600 !important; //changed so search/filter drawer isnt hidden
  width: 100vw;
  background-color: ${props => props.theme.colors.primary};
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
`

const TopBarWrapper = styled(Container)`
  width: 100%;

  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`

type TopBarLinkProps = {
  whiteStyle: string
}

const TopBarLink = styled(props => <Link {...props} />)<TopBarLinkProps>`
  background-color: ${props => (props.$whiteStyle === "true" ? props.theme.tints.white : props.theme.colors.primary)};
  color: ${props => (props.$whiteStyle === "true" ? props.theme.colors.primary : props.theme.tints.white)};
  font-size: 18px;
  font-weight: 600;
  /* border: 1px solid red; */
  padding: ${props => (props.$whiteStyle === "true" ? `16px 18px` : `16px 18px 16px 0`)};
  &:hover {
    text-decoration: none;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 50vw;
    text-align: center;
    font-size: 13px;

    line-height: 18px;
    text-align: center;
  }
`
const TopBarLinkEx = styled.a`
  height: 100%;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.tints.white};
  font-size: 18px;
  font-weight: 600;
  padding: 16px 18px 16px 0;
  &:hover {
    text-decoration: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 50vw;
    text-align: center;
    font-size: 13px;

    line-height: 18px;
    text-align: center;
  }
`

const TopBarGFLinks = styled.div`
  color: ${props => props.theme.tints.white};
  font-size: 18px;
  font-weight: 600;
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
`
const TopBarLogin = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.tints.white};
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`

const SearchIcon = styled(Search)`
  margin-right: 18px;
`
const ProfileIcon = styled(Profile)`
  margin-right: 18px;
`

const ProfileLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`
