import { Drawer, List, ListItem, makeStyles } from "@material-ui/core"
import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Item, NavigationProps } from "./NavBar"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
const useStyles = makeStyles(theme => ({
  drawer: {
    width: "100%",

    // border: "2px solid red",
  },
}))

export interface NavigationProps {
  stickyNavigation: Array<Item>
  setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>
  mobileNavOpen: boolean
}

export const MobileNavigation: React.FC<NavigationProps> = ({ stickyNavigation, mobileNavOpen, setMobileNavOpen }) => {
  const classes = useStyles()
  return (
    <>
      <StyledDrawer
        anchor={"top"}
        open={mobileNavOpen}
        onClose={() => {
          setMobileNavOpen(false)
        }}
        classes={{
          paper: classes.drawer,
        }}
      >
        <StyledList>
          {stickyNavigation.map((edge, key) => (
            <StyledListItem key={key}>
              <StyledLink to={edge.slug} key={key} className="dark-link">
                <span>{edge.title}</span>
                <ArrowForwardIosIcon style={{ width: 12, height: "auto" }} />
              </StyledLink>
            </StyledListItem>
          ))}
          {/* <StyledListItem>
            <Button size={SizeType.medium} to={"/register"} style={{ fontWeight: 600 }}>
              REGISTER NOW
            </Button>
          </StyledListItem> */}
        </StyledList>
      </StyledDrawer>
    </>
  )
}

const StyledDrawer = styled(Drawer)``

const StyledList = styled(List)`
  margin-top: 107px !important;
  margin-left: 23px !important;
`
const StyledListItem = styled(ListItem)`
  border-bottom: 1px solid ${props => props.theme.tints.lightGrey};
  padding-left: 0px !important;
`
const StyledLink = styled(Link)<LinkProp>`
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  &,
  &:link,
  &:visited {
    color: ${props => props.theme.colors.primary};
    font-size: 16px;
    height: 68px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    &::after {
      position: absolute;
      content: " ";
      height: 3px;

      bottom: -1px;
      left: ${props => (props.firstLink ? "0" : "0%")};
      background: ${props => props.theme.colors.turquoise};
      transition: ease-out 0.3s;
      margin: 0 auto;
      transform: scaleX(0);
    }
    &:hover::after,
    &:active::after,
    &:focus::after {
      transform: scaleX(1);
    }
  }
  &:hover,
  &:focus {
    color: ${props => props.theme.colors.turquoise};
    text-decoration: none !important;
  }
`
