import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { IconButton } from "@material-ui/core"
import TwitterIcon from "@material-ui/icons/Twitter"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import InstagramIcon from "@material-ui/icons/Instagram"
import FacebookIcon from "@material-ui/icons/Facebook"
import PinterestIcon from "@material-ui/icons/Pinterest"
import Vimeo from "../../assets/svg/Vimeo.svg"
import FooterPartners from "./FooterPartners"
import { Container } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  customHoverFocus: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#2e2e2e" },
    backgroundColor: "black",
    color: "white",
  },
}))
const Footer: React.FC = () => {
  const classes = useStyles()

  return (
    <>
      <FullWidth>
        <FooterPartners />

        <FooterContainer>
          <FooterGrid>
            <FooterColumn>
              <h5 style={{ textTransform: "capitalize", fontSize: "1.25rem" }}>Graduate Fashion Week</h5>
              <ColumnContent>
                <LinkFooter to="/about-us">About</LinkFooter>
                <LinkFooter to="/get-in-touch">Get in Touch </LinkFooter>
                {/* <LinkFooter to="/about-us">Register your interest</LinkFooter> */}
                {/* <LinkFooter to="/terms-and-conditions">
                  Terms and conditions
                </LinkFooter> */}
                <LinkFooter to="/privacy-policy">Privacy Policy</LinkFooter>
              </ColumnContent>
            </FooterColumn>
            <FooterColumn>
              <h5 style={{ textTransform: "capitalize", fontSize: "1.25rem" }}>Get In Touch</h5>
              <ColumnContent>
                <PhoneFooter href="tel:+44 (0) 20 8158 3170">+44 (0) 20 8158 3170</PhoneFooter>
                <MailFooter href="mailto:enquiries@graduatefashionweek.com">
                  enquiries@graduatefashionweek.com
                </MailFooter>
                <p>Registered Charity No. 117313</p>
              </ColumnContent>
            </FooterColumn>
            {/* <FooterColumn hidden></FooterColumn> */}
            <FooterColumnRight>
              <h5 style={{ textTransform: "capitalize", fontSize: "1.25rem" }}>Stay connected</h5>
              <ColumnContent>
                <IconsBox>
                  <IconButton
                    aria-label="E-mail"
                    href={"mailto:enquiries@graduatefashionweek.com"}
                    target="_blank"
                    className={classes.customHoverFocus}
                  >
                    <MailOutlineIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Linkedin"
                    href={
                      "https://www.linkedin.com/company/graduate-fashion-week?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A245773%2Cidx%3A2-1-2%2CtarId%3A1461236319717%2Ctas%3Agraduate%20fashion"
                    }
                    className={classes.customHoverFocus}
                    target="_blank"
                  >
                    <LinkedInIcon />
                  </IconButton>

                  <IconButton
                    aria-label="Twitter"
                    href={"https://twitter.com/OfficialGFW/"}
                    target="_blank"
                    className={classes.customHoverFocus}
                  >
                    <TwitterIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Instagram"
                    href={"https://www.instagram.com/officialgfw/"}
                    target="_blank"
                    className={classes.customHoverFocus}
                  >
                    <InstagramIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Facebook"
                    href={"https://www.facebook.com/GraduateFashionWeek"}
                    target="_blank"
                    className={classes.customHoverFocus}
                  >
                    <FacebookIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Pinterest"
                    href={"https://www.pinterest.com/officialgfw/"}
                    target="_blank"
                    className={classes.customHoverFocus}
                  >
                    <PinterestIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    href={"https://vimeo.com/graduatefashionweek"}
                    target="_blank"
                    className={classes.customHoverFocus}
                  >
                    <Vimeo />
                  </IconButton>
                </IconsBox>
              </ColumnContent>
            </FooterColumnRight>
          </FooterGrid>
        </FooterContainer>
        <Container>
          <Copyright>{new Date().getFullYear()} ©graduatefashionweek</Copyright>
        </Container>
      </FullWidth>
    </>
  )
}

export default Footer

const FooterContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: flex-start;
  align-items: flex-start;
`

const FullWidth = styled.div`
  width: 100vw;
  /* display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: flex-start;
  align-items: flex-start; */
`
const FooterGrid = styled.div`
  margin-top: 28px;
  margin-bottom: 58px;

  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`

interface IFooterColumn {
  hidden?: boolean
}

const FooterColumn = styled.div<IFooterColumn>`
  display: flex;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: ${props => (props.hidden ? "none" : "flex")};
  }
`
const FooterColumnRight = styled.div<IFooterColumn>`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 5;
  /* border: 1px solid red; */
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-column-start: 3;
    grid-column-end: 4;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: ${props => (props.hidden ? "none" : "flex")};
    grid-column-start: initial;
    grid-column-end: initial;
  }
`

const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #4a4a4a;
  letter-spacing: 0;
  font-weight: 400;
  line-height: 30px;
`

const LinkFooter = styled(Link)`
  font-size: 16px;
  color: #4a4a4a;
  letter-spacing: 0;
  font-weight: 400;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  &:hover {
    color: ${props => props.theme.colors.turquoise};
  }
`

const PhoneFooter = styled.a`
  color: #4a4a4a;

  &:hover {
    color: ${props => props.theme.colors.turquoise};
    text-decoration: underline;
  }
`

const MailFooter = styled.a`
  text-decoration: underline;
`

const Copyright = styled.p`
  font-size: 16px;
  font-weight: 600;
  align-self: flex-start;
  margin-bottom: 16px;
`

const IconsBox = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  max-width: 220px;
  gap: 14px;
  & > button {
    width: 40px;
    background-color: ${props => props.theme.colors.primary};
    height: 40px;
    color: white;
    &:hover {
      background-color: ${props => props.theme.colors.turquoise};
    }
  }
`
