import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Button, ButtonSecondary } from "../Buttons/Buttons"
import { Container, IconButton } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import ClearIcon from "@material-ui/icons/Clear"
import LogoIcon from "../../assets/svg/GFW_Logo.svg"
import { MobileNavigation } from "./MobileNavigation"
import Search from "../../assets/svg/SearchBlue.svg"
export interface Item {
  title: string
  slug: string
}

export interface NavigationProps {
  stickyNavigation: Array<Item>
}

const Navigation: React.FC<NavigationProps> = ({ stickyNavigation }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [appendedStickyNav, setAppendedStickyNav] = useState(stickyNavigation)

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      const tempAppendedStickyNav = stickyNavigation.map(edge => {
        let active = false

        if (window != undefined) {
          const location = window.location.href
          const re = new RegExp(edge.slug, "g")
          active = location.match(re) ? true : false
        }
        return { ...edge, active }
      })
      setAppendedStickyNav(tempAppendedStickyNav)
    }
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      <FullWidth>
        <MainContainer maxWidth="lg">
          <LogoLink to="/">
            <Logo />
          </LogoLink>

          <NavContainer>
            {/* {isLoggedIn() ? null : (
              <ButtonSecondarySignIn size={SizeType.small} to={"/events/login"}>
                SIGN IN
              </ButtonSecondarySignIn>
            )}

            <Link to={"/search"}>
              <SearchIcon />
            </Link> */}
            <StickyNav>
              {appendedStickyNav.map((edge, key) => {
                return (
                  <StyledLink
                    to={edge.slug}
                    key={"naivgaiton-" + key}
                    // activeLink={edge.active}
                    className="dark-link"
                    //  onClick={() => setSelectedLink(edge.title)}
                  >
                    {edge.title}
                  </StyledLink>
                )
              })}
            </StickyNav>
            <MobileToolbar>
              <IconButton
                onClick={() => {
                  setMobileNavOpen(!mobileNavOpen)
                }}
              >
                {mobileNavOpen ? <StyledClearIcon /> : <StyledMenuIcon />}
              </IconButton>
            </MobileToolbar>
            {/* <Button size={SizeType.medium} style={StyleType.mint}>
                text
              </Button> */}

            {/* {isLoggedIn() ? null : (
              <ButtonNavBar size={SizeType.medium} to={"/register"}>
                REGISTER NOW
              </ButtonNavBar>
            )} */}
          </NavContainer>
          {/* </MainGrid> */}
        </MainContainer>
      </FullWidth>
      <MobileNavigation
        stickyNavigation={stickyNavigation}
        setMobileNavOpen={setMobileNavOpen}
        mobileNavOpen={mobileNavOpen}
      />
    </>
  )
}

export default Navigation

const StyledClearIcon = styled(ClearIcon)`
  width: 30px !important;
  height: 30px !important;
`

const StyledMenuIcon = styled(MenuIcon)`
  width: 30px !important;
  height: 30px !important;
`

const MobileToolbar = styled.div`
  display: none;
  @media screen and (max-width: 599px) {
    display: flex;
    align-items: center;
  }
`

const FullWidth = styled.div`
  width: 100vw;
  background-color: ${props => props.theme.tints.white};
  display: flex;
  /* justify-content: center; */
  border-bottom: 1px solid #f4f4f4;
  z-index: 1600 !important; //changed so that the drawer for search portfolios isn't hidden
  /* border: 1px solid red; */
`

const MainContainer = styled(Container)`
  background-color: ${props => props.theme.tints.white};
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
`
interface LinkProp {
  activeLink: boolean
}
const StyledLink = styled(Link)<LinkProp>`
  &,
  &:link,
  &:visited {
    color: ${props => props.theme.colors.primary};
    font-size: 18px;
    height: 90px;
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      height: 60px;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    &::after {
      position: absolute;
      content: " ";
      height: 3px;
      width: 100%;
      bottom: -1px;
      left: ${props => (props.firstLink ? "0" : "0%")};
      background: ${props => props.theme.colors.turquoise};
      transition: ease-out 0.3s;
      margin: 0 auto;
      transform: scaleX(0);
      transform: ${props => (props.activeLink ? "scaleX(1)" : "scaleX(0)")};
    }
    &:hover::after,
    &:active::after,
    &:focus::after {
      transform: scaleX(1);
    }
  }
  &:hover,
  &:focus {
    color: ${props => props.theme.colors.turquoise};
    text-decoration: none !important;
  }
`

const StickyNav = styled.div`
  display: none;
  & a:not(:last-of-type) {
    margin-right: 30px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
  }
`
const NavContainer = styled.div`
  display: flex;
  align-items: center;
  /* gap: 30px; */
  height: 90px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 60px;
  }
`

const ButtonNavBar = styled(Button)`
  margin-left: 30px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`

const Logo = styled(LogoIcon)`
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 50px;
    height: 50px;
  }
`
const LogoLink = styled(Link)`
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 50px;
    height: 50px;
  }
`

const SearchIcon = styled(Search)`
  margin: 5px 5px 0 20px;
  height: 24px;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
  /* color:black;
 background-color: black;
 border-radius: 50%;
 padding:3px; */
`
const ButtonSecondarySignIn = styled(ButtonSecondary)`
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`
