import React from "react"
import { ThemeProvider } from "styled-components"

const theme = {
  colors: {
    primary: "#000000", //black
    secondary: "#E72569", //hotpink
    blue: "#302879",
    turquoise: "#0DB0E7",
    turquoiseHover: "#016688",

    mint: "#49D8C8",
    yellow: "#FFE500",
    purple: "#831f82",
    pink: "#F5AEBB",
    redOrange: "#E63911",
    grey1: "#4A4A4A",

    carouselLetter: "#a9dbeb",
    secondaryHover: "#006793",
    secondaryBorder: "#DADADA",
    dropdownBorder: "#DDDDDD",
    quoteBox: "#ECF9FD",
  },
  tints: {
    lightGrey: "#ECECEC",
    lighterGrey: "#FAFAFA",
    white: "#FFFFFF",
    cardbackground: "#e3e3e3",
  },
  lines: {
    grey: "#CBCBCB",
  },
  support: {
    redError: "#F56565",
    greenSuccess: "#4F9F31",
    yellowWarning: "#FFD209",
  },
  fonts: "Barlow Condensed",
  width: {
    boxed: "1200px",
    fullWidth: "100vw",
  },
  paddingPage: {
    boxed: "5vw",
  },

  breakpoints: {
    xl: "1920px",
    lg: "1280px",
    md: "960px",
    sm: "600px",
  },
}
const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>

export default Theme
