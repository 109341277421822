import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container } from "@material-ui/core"

const FooterPartners: React.FC = () => {
  const partners = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "FooterPartners" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(width: 80, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Container>
      <FooterPartnersContainer>
        <h5 style={{ textTransform: "capitalize", fontSize: "1.25rem" }}>Graduate Fashion Week in partnership with:</h5>
        <LogoContainer>
          <FooterPartnersGrid>
            {partners.allFile.edges.map((item, id) => {
              return (
                <GatsbyImage
                  image={item.node.childImageSharp.gatsbyImageData}
                  style={{ height: "80", width: "80" }}
                  imgStyle={{ objectFit: "cover" }}
                  alt={"footer partners"}
                  key={id}
                  //  placeholderStyle={{ opacity: 0 }}
                  loading={"eager"}
                />
              )
            })}
          </FooterPartnersGrid>
        </LogoContainer>
      </FooterPartnersContainer>
    </Container>
  )
}

export default FooterPartners

const FooterPartnersContainer = styled.div`
  margin-top: 60px;
  display: none;
`
const LogoContainer = styled.div`
  max-height: 90px;
  max-width: 560px;
  /* overflow: hidden; */
  /* border: 1px solid blue; */
`

// const FooterPartnersGrid = styled.div`
//   border: 1px solid red;
//   width: 100%;
//   min-height: 80px;
//   max-height: 80px;
//   display: grid;
//   grid-gap: 1rem;
//   grid-template-columns: repeat(auto-fit, minmax(0px, 80px));
//   margin-bottom: 28px;
// `
const FooterPartnersGrid = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  max-height: 80px;
  display: grid;
  grid-template-columns: repeat(7, minmax(1%, 1fr));
  grid-template-rows: repeat(1, minmax(1%, 1fr));
`
