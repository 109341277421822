import { ApiUrl } from "../util/apiUrl"

export const isBrowser = () => typeof window !== "undefined"
export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gfwUser") ? JSON.parse(window.localStorage.getItem("gfwUser")) : {}
export const setUser = user => window.localStorage.setItem("gfwUser", JSON.stringify(user))

export const handleLogin = ({ username, password }) => {
  return fetch(`${ApiUrl}/login`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      Authorization: "Basic " + btoa(username + ":" + password),
    },
  })
    .then(response => response.json())
    .then(data => {
      if (data.message) {
        return false
      }
      setUser(data.user)
      return true
    })
    .catch(err => {
      console.error(err)
      return false
    })
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.token
}
export const logout = callback => {
  setUser({})
  callback()
}
