import React, { useState } from "react"
import Theme from "../style/theme"
import styled from "styled-components"
import GlobalStyles from "../style/GlobalStyle"
import TopBar from "./Navigation/TopBar"
import NavBar from "./Navigation/NavBar"
import Footer from "./Footer/Footer"
import Helmet from "react-helmet"
import PartnersInfiniteCarousel from "./Footer/PartnersInfiniteCarousel"
import "react-lazy-load-image-component/src/effects/blur.css"
import { CookieBar } from "./CookieBar/CookieBar"
import { graphql, StaticQuery } from "gatsby"

const stickyNavigation = [
  {
    title: "ABOUT US",
    slug: "/about-us",
  },
  {
    title: "EVENTS & LIVESTREAM",
    slug: "/events",
  },
  {
    title: "SEARCH PORTFOLIOS",
    slug: "/search-portfolios",
  },
  {
    title: "UNIVERSITIES",
    slug: "/universities",
  },
  {
    title: "PARTNERSHIPS",
    slug: "/partnerships",
  },
  // {
  //   title: "HOME-EVENT-PREVIEW",
  //   slug: "/home-event",
  // },
]

const stickyNavigationMaster = [
  {
    title: "ABOUT US",
    slug: "/about-us",
  },
  {
    title: "EVENTS & LIVESTREAM",
    slug: "/events",
  },
  {
    title: "SEARCH PORTFOLIOS",
    slug: "/search-portfolios",
  },
  {
    title: "UNIVERSITIES",
    slug: "/universities",
  },
  {
    title: "PARTNERSHIPS",
    slug: "/partnerships",
  },
  // {
  //   title: "HOME-EVENT-PREVIEW",
  //   slug: "/home-event",
  // },
]

interface LayoutInterface {
  partners?: any
  title: string
}

export const Layout: React.FC<LayoutInterface> = ({ children, partners, title }) => {
  const [cookiesClosed, setCookiesClosed] = useState(false)
  const closeCookies = () => {
    setCookiesClosed(!cookiesClosed)
  }
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              amplifyBranch
            }
          }
        }
      `}
      //
      render={data => {
    
        return (
          <Theme>
            <Helmet title="Graduate Fashion Week">
              <title>{title}</title>
              <meta
                name="description"
                content={
                  "Join us to celebrate 30 years of Graduate Fashion Week, the world's largest showcase of BA fashion talent. During our anniversary year, our globally renowned Graduate Fashion Week will be an innovative hybrid event bringing both physical and ground-breaking digital showcases of UK and International talent to its audiences this June. We also have a new home at Coal Drops Yard, London using the spectacular location and venues to host GFW events."
                }
              />
              <link rel="preload" as="style" href="https://fonts.googleapis.com/css?family=Barlow Condensed" />
              <script type="text/javascript" src="//play.webvideocore.net/js/dplayer.js" />
            </Helmet>
            <Root>
              <GlobalStyles />
              <CookieBar closeCookies={closeCookies} />
              <TopBar />
              <NavBar
                stickyNavigation={
                  data.site.siteMetadata.amplifyBranch !== "master" ? stickyNavigation : stickyNavigationMaster
                  // stickyNavigationMaster
                }
              />
              {children}
              <PartnersInfiniteCarousel />
              <Footer />
            </Root>
          </Theme>
        )
      }}
    />
  )
}

const Root = styled.div`
  width: 100vw;
  background-color: ${props => props.theme.tints.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
